import React, { useState, useRef } from 'react'
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'

import styles from './Comment.module.css'
import { db } from '../../../firebase/Firebase'
import ChatIconImage from '../../../assets/images/chat_icon.svg'
import SmileIconImage from '../../../assets/images/smile.png'
import logoImage from '../../../assets/images/logo_white.png'
import Like from '../Like/Like'
import axios from 'axios'

const Comment = (props) => {
  const [commentContent, setCommentContent] = useState('')
  const uid = localStorage.getItem('userId')
  const [showEmoji, setShowEmoji] = useState(false)
  const ref = useRef(null)
  let currentDate = new Date()
  let dd = String(currentDate.getDate()).padStart(2, '0')
  let mm = currentDate.toLocaleString('default', { month: 'short' })
  let yyyy = currentDate.getFullYear()
  currentDate = mm + ' ' + dd + ', ' + yyyy

  const handlePostComment = async () => {
    if (commentContent) {
      let newPostRef = db.ref('comment/').push()
      let commentKey =
        typeof newPostRef._delegate != 'undefined'
          ? newPostRef._delegate._path.pieces_[1]
          : newPostRef.path.pieces_[1]
      let userEmail = localStorage.getItem('userEmail')
      let allNotes = []
      let type = '0'

      await db.ref('comment/').once('value', (snapshot) => {
        snapshot.forEach((snap) => {
          if (
            snap.val().category_key == props.item.key &&
            snap.val().mail == userEmail
          ) {
            allNotes.push(snap.val())
          }
        })
      })

      if (allNotes.length) {
        if (allNotes[0].type === '1' || allNotes[0].type === '2') {
          type = '2'
        }
        if (allNotes[0].type === '1') {
          await db.ref('comment/' + allNotes[0].key).remove()
        }
      }

      newPostRef.update({
        category: props.item.category,
        category_key: props.item.key,
        date: currentDate,
        key: commentKey,
        mail: userEmail,
        type: type,
        username: props.firstName ? props.firstName : '',
        value: commentContent,
      })
      props.handleRefresh()
      handleSendEmail()
      setCommentContent('')
    }
  }

  const onEmojiClick = (e) => {
    let emoji = e.native
    const cursor = ref.current.selectionStart
    const text =
      commentContent.slice(0, cursor) +
      ' ' +
      emoji +
      '  ' +
      commentContent.slice(cursor)
    setCommentContent(text)
    setShowEmoji((prevState) => !prevState)
    console.log(showEmoji)
  }

  const handleEmojiToggle = () => {
    setShowEmoji((prevState) => !prevState)
  }

  const handleSendEmail = async () => {
    let commentCheckbox = '0'
    let email = ''
    let invitedUserId = props.item.userId
    await db
      .ref('account/' + invitedUserId)
      .once('value')
      .then(function (snapshot) {
        let checkAccount = snapshot.exists()
        if (checkAccount) {
          snapshot.forEach((snap) => {
            if (snap.key === 'userCommentCheck') {
              commentCheckbox = snap.val()
            } else if (snap.key == 'email') {
              email = snap.val()
            }
          })
        }
      })

    if (commentCheckbox == '1') {
      let firstName = props.firstName ? props.firstName : ''
      let childName = props.item.kid ? props.item.kid : ''
      let photoTitle = props.item.photoTitle ? props.item.photoTitle : ''
      let photoDesc = props.item.photoDescription
        ? props.item.photoDescription
        : ''
      let photoDate = props.item.dateTaken ? props.item.dateTaken : ''
      let commentKey = props.item.key ? props.item.key : ''
      let imageURL = ''
      if (props.item.videoMode && props.item.videoMode == '1') {
        imageURL = props.item.urlThumbnail
      } else {
        imageURL = props.item.url
      }

      let template = `
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <table role="presentation" border="0" width="100%" cellspacing="0" cellpadding="0">
        <tbody>
        <tr>
        <td style="padding: 20px 0 30px 0;">
        <table style="border: 20px solid #7E91F5; border-bottom: 40px solid #7E91F5;" border="0" width="600" cellspacing="0" cellpadding="0" align="center">
        <tbody>
        <tr>
        <td style="padding: 20px 0 30px 0;" align="center" bgcolor="#7E91F5"><img style="display: block;" src="${logoImage}" alt="Kidstory" width="128" height="32" /></td>
        </tr>
        <tr>
        <td style="padding: 40px 30px 40px 30px;" bgcolor="#ffffff" border-radius: 7px; -webkit-border-radius: 7px; -moz-border-radius: 7px;>
        <table style="border-collapse: collapse;" border="0" width="100%" cellspacing="0" cellpadding="0">
        <tbody>
        <tr>
        <td>
        <table style="border-collapse: collapse;" border="0" width="100%" cellspacing="0" cellpadding="0">
        <tbody>
        <tr>
        <td>
        <h3 style="color: #797979; font-size: 14x; padding-top: 10px;">NOTIFICATION</h3>
        </td>
        </tr>
        <tr>
        <td>
        <p style="color: #000000; font-size: 16px; margin-top: -5px; margin-bottom: 50px;">${firstName} commented ${childName}'s moment.</p>
        </td>
        </tr>
        </tbody>
        </table>
        </td>
        </tr>
        <tr>
        <td>
        <table>
        <tbody>
        <tr>
        <td style="color: #153643; font-family: Arial, sans-serif; align-items: center;"><img style="display: block; width: 425px; height: auto; margin-left: 35px;" src="${imageURL}" alt="Kidstory" width="160px" height="160px" /></td>
        </tr>
        </tbody>
        </table>
        </td>
        </tr>
        <tr>
        <td style="color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 24px; padding: 20px 0 30px 35px; width: 425px;">
        <p style="margin: 0; font-size: 16px; color: #000000;">${photoTitle}</p>
        <p style="margin: 0; font-size: 16px; color: #424242; margin-top: 5px; width: 425px;">${photoDesc}</p>
        <p style="margin: 0; font-size: 12px; color: #424242; margin-top: 10px;">${photoDate}</p>
        </td>
        </tr>
        <tr>
        <td style="border-bottom: 1px solid #e0e0e0; padding-top: 10px; padding-bottom: 30px; padding-left: 35px; cursor: pointer;"><a style="cursor: pointer;" href="https://app.kidstory.life/life"> <button style="background-color: #ea718d; cursor: pointer; font-size: 14px; color: #ffffff; border: none; padding: 15px 45px; border-radius: 2px; width: 425px;">View Moment</button> </a></td>
        </tr>
        <tr>
        <td>
        <table style="border-collapse: collapse;" border="0" width="100%" cellspacing="0" cellpadding="0">
        <tbody>
        <tr>
        <td>
        <p style="color: #797979; font-size: 12px; padding-top: 20px; padding-bottom: 10px;">Download the Kidstory<sup style="font-size: 8px;">TM</sup>app</p>
        </td>
        </tr>
        <tr>
        <td><a href="https://apps.apple.com/ca/app/kidstory-memories/id1385761112"> <img style="display: block; width: 110px; cursor: pointer;" src="https://app.kidstory.life/static/media/appstore_icon.6433fa92.svg" alt="AppStore" width="160px" height="35px" /> </a></td>
        </tr>
        </tbody>
        </table>
        </td>
        </tr>
        </tbody>
        </table>
        </td>
        </tr>
        <tr>
        <td style="padding: 0 30px 40px 30px;">
        <table style="border-collapse: collapse;" border="0" width="100%" cellspacing="0" cellpadding="0">
        <tbody>
        <tr>
        <td style="color: #797979; font-family: Arial, sans-serif; font-size: 10px;">
        <p style="margin: 0;">Copyright<br />kidstory.life</p>
        </td>
        <td align="right">
        <table style="border-collapse: collapse;" border="0" cellspacing="0" cellpadding="0">
        <tbody>
        <tr>
        <td style="color: #797979; font-family: Arial, sans-serif; font-size: 10px;"><a href="https://www.kidstory.life/privacy"><span style="margin: 0; padding-right: 20px; cursor: pointer;">Privacy Policy</span></a></td>
        <td style="color: #797979; font-family: Arial, sans-serif; font-size: 10px;"><a href="https://www.kidstory.life/terms-of-use"><span style="margin: 0; padding-right: 20px; cursor: pointer;">Terms of Use</span></a></td>
        <td style="color: #797979; font-family: Arial, sans-serif; font-size: 10px;"><a href="mailto:carolinepaxton@rogers.com"><span style="margin: 0; cursor: pointer;">Contact Us</span></a></td>
        </tr>
        </tbody>
        </table>
        </td>
        </tr>
        </tbody>
        </table>
        </td>
        </tr>
        </tbody>
        </table>
        </td>
        </tr>
        </tbody>
        </table>`

      axios
        .post('https://app.kidstory.life:9000/likeEmailAPI', {
          data: template,
          email: email,
          uid: uid,
          type: 'commented',
          commentKey: commentKey,
          firstName: firstName,
          childName: childName,
          commentContent: commentContent,
          currentDate: currentDate,
        })
        .then((res) => {
          console.log('Email was sent successfully. ')
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  return (
    <div className={styles.AddComment}>
      <Like
        item={props.item}
        firstName={
          localStorage.getItem('firstName')
            ? localStorage.getItem('firstName')
            : ''
        }
        refreshPage={props.handleRefresh}
      />
      <div className={styles.InputComment}>
        <img className={styles.LikeImage} src={ChatIconImage} />
        <input
          name="comment"
          value={commentContent}
          ref={ref}
          onChange={(e) => setCommentContent(e.target.value)}
          type="text"
          placeholder="Add comment here..."
        ></input>
        <img
          className={styles.SmileImage}
          src={SmileIconImage}
          onClick={() => handleEmojiToggle()}
        />
      </div>
      <div className={styles.CommentPost} onClick={() => handlePostComment()}>
        <button>POST</button>
      </div>
      <div className={styles.EmojiKeyboard}>
        {showEmoji && <Picker onSelect={onEmojiClick} />}
      </div>
    </div>
  )
}

export default Comment
