import React from 'react'

import styles from './MainHeader.module.css'
import Logo from '../UI/Logo/Logo'
import AppStore from '../UI/Buttons/AppStore/AppStore'
import Hamburger from '../UI/Buttons/Hamburger/Hamburger'

const mainHeader = (props) => {
  return (
    <header className={styles.MainHeader}>
      <div className={styles.Container}>
        <div className={styles.Logo}>
          <Logo />
        </div>
        <div className={styles.Hamburger} onClick={props.clicked}>
          <Hamburger />
        </div>
        <nav className={styles.AppStoreButton}>
          <AppStore />
        </nav>
        <div className={styles.PageName}>
          <p>{props.pageName}</p>
        </div>
      </div>
    </header>
  )
}

export default mainHeader
