import React, { Component } from 'react'

import styles from './Google.module.css'
import GoogleIcon from '../../../../assets/images/google_icon.png'

const google = (props) => {
  return <img className={styles.Button} src={GoogleIcon} alt="Google" />
}

export default google
