import React, { Component } from 'react'

import MoonLoader from 'react-spinners/MoonLoader'
import { Spinner } from '../../components/Variables/Variables'
import styles from './Login.module.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import AppleIcon from '../../components/UI/Buttons/Apple/Apple'
import FacebookIcon from '../../components/UI/Buttons/Facebook/Facebook'
import GoogleIcon from '../../components/UI/Buttons/Google/Google'
import GroupImage from '../../assets/images/group_image.png'
import Modal from '../../components/UI/Modal/Modal'
import ForgotPassword from '../../components/UI/Modal/ForgotPassword/ForgotPassword'

import firebase, { auth, db } from '../../firebase/Firebase'

class LoginSuccess extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      inCorrectEmail: false,
      loading: false,
      showModal: false,
    }
  }

  handleEmail = (event) => {
    this.setState({
      email: event.target.value,
      inCorrectEmail: false,
    })
  }

  handlePassword = (event) => {
    this.setState({
      password: event.target.value,
    })
  }

  handleSignin = (event) => {
    event.preventDefault()
    this.setState({
      loading: true,
    })
    const reqEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (reqEmail.test(String(this.state.email).toLowerCase())) {
      auth
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        .then(async (res) => {
          const token = await Object.entries(res.user)[5][1].b
          const firstName = ''
          const lastName = ''
          localStorage.setItem('userId', res.user.uid)
          localStorage.setItem('userEmail', res.user.email)
          localStorage.setItem('token', token)
          this.getUserNameFromProfile(res.user.uid)
          this.handleAccount(res.user.uid, res.user.email, firstName, lastName)
          this.setState({
            loading: false,
          })
        })
        .catch((err) => {
          this.setState({
            loading: false,
          })
          alert(err.message)
          console.log('Signin error => ', err)
        })
    } else {
      this.setState({
        loading: false,
        inCorrectEmail: true,
      })
    }
  }

  handleAppleSignin = () => {
    this.setState({
      loading: true,
    })
    let provider = new firebase.auth.OAuthProvider('apple.com')
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        if (res.credential) {
          /** @type {firebase.auth.OAuthCredential} */
          console.log('Apple login was successful.')
          let credential = res.credential
          let user = res.user
          let accessToken = credential.accessToken
          let firstName = res.user.displayName
            ? res.user.displayName.split(' ').slice(0, -1).join(' ')
            : ''
          let lastName = res.user.displayName
            ? res.user.displayName.split(' ').slice(-1).join(' ')
            : ''
          let idToken = credential.idToken
          localStorage.setItem('userEmail', user.email)
          localStorage.setItem('userId', user.uid)
          localStorage.setItem('token', accessToken)
          this.handleAccount(res.user.uid, res.user.email, firstName, lastName)
          this.setState({
            loading: false,
          })
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        })
        alert(error.message)
      })
  }

  handleFacebookSignin = () => {
    this.setState({
      loading: true,
    })
    const provider = new firebase.auth.FacebookAuthProvider()
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        if (res.credential) {
          /** @type {firebase.auth.OAuthCredential} */
          let credential = res.credential
          let token = credential.accessToken
          let firstName = res.user.displayName
            ? res.user.displayName.split(' ').slice(0, -1).join(' ')
            : ''
          let lastName = res.user.displayName
            ? res.user.displayName.split(' ').slice(-1).join(' ')
            : ''
          localStorage.setItem('userEmail', res.user.email)
          localStorage.setItem('userId', res.user.uid)
          localStorage.setItem('token', token)
          this.handleAccount(res.user.uid, res.user.email, firstName, lastName)
          this.setState({
            loading: false,
          })
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        })
        alert(error.message)
      })
  }

  handleGoogleSignin = async () => {
    this.setState({
      loading: true,
    })
    const provider = new firebase.auth.GoogleAuthProvider()
    await firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        if (res.credential) {
          /** @type {firebase.auth.OAuthCredential} */
          let credential = res.credential
          let token = credential.accessToken
          console.log('res.user.displayName ', res.user.displayName)
          let firstName = res.user.displayName
            ? res.user.displayName.split(' ').slice(0, -1).join(' ')
            : ''
          let lastName = res.user.displayName
            ? res.user.displayName.split(' ').slice(-1).join(' ')
            : ''
          localStorage.setItem('userEmail', res.user.email)
          localStorage.setItem('userId', res.user.uid)
          localStorage.setItem('token', token)
          this.setState({
            loading: false,
          })
          this.handleAccount(res.user.uid, res.user.email, firstName, lastName)
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        })
        alert(error.message)
      })
  }

  getUserNameFromProfile = (currentUserId) => {
    db.ref('account/' + currentUserId).once('value', (snapshot) => {
      let checkAccount = snapshot.exists()
      if (checkAccount) {
        let savedFirstName = ''
        let savedLastName = ''
        snapshot.forEach((snap) => {
          if (snap.key == 'firstName') {
            savedFirstName = snap.val()
          } else if (snap.key == 'lastName') {
            savedLastName = snap.val()
          }
        })

        if (savedFirstName) {
          localStorage.setItem('firstName', savedFirstName)
        } else {
          localStorage.setItem('firstName', '')
        }

        if (savedLastName) {
          localStorage.setItem('lastName', savedLastName)
        } else {
          localStorage.setItem('lastName', '')
        }
      } else {
        localStorage.setItem('firstName', '')
        localStorage.setItem('lastName', '')
      }
      this.props.history.push('/life')
    })
  }

  handleAccount = async (uid, email, firstName, lastName) => {
    await db
      .ref('account/' + uid)
      .once('value')
      .then(function (snapshot) {
        if (snapshot.exists()) {
          let savedFirstName = ''
          let savedLastName = ''
          snapshot.forEach((snap) => {
            if (snap.key == 'firstName') {
              savedFirstName = snap.val()
            } else if (snap.key == 'lastName') {
              savedLastName = snap.val()
            }
          })
          if (savedFirstName != '' || savedLastName != '') {
            localStorage.setItem('firstName', savedFirstName)
            localStorage.setItem('lastName', savedLastName)
          } else {
            db.ref('account/' + uid).update({
              email: email,
              firstName: firstName ? firstName : savedFirstName,
              lastName: lastName ? lastName : savedLastName,
            })
            localStorage.setItem('firstName', firstName)
            localStorage.setItem('lastName', lastName)
          }
        } else {
          db.ref('account/' + uid).set({
            email: email,
            firstName: firstName && firstName,
            lastName: lastName && lastName,
            userBirthCheck: '1',
            userCommentCheck: '1',
            userLikeCheck: '1',
          })
          localStorage.setItem('firstName', firstName)
          localStorage.setItem('lastName', lastName)
        }
      })
      .catch((error) => {
        alert(error.message)
      })

    this.props.history.push('/life')
  }

  handleShowModal = () => {
    this.setState({
      showModal: true,
    })
  }

  handleHideModal = () => {
    this.setState({
      showModal: false,
    })
  }

  render() {
    return (
      <div>
        <Header />
        <section className={styles.MainConent}>
          <div className={styles.Container}>
            <div className={styles.AccessContent}>
              <div className={styles.AccessTitle}>
                <h1>
                  Your password has been successfully reset, you can now log in.
                </h1>
                {/* <p>Charlotte's digital scrapbook of memories.</p> */}
              </div>
              <div className={styles.AccessBox}>
                <form onSubmit={this.handleSignin}>
                  {/* <div className={styles.AccessSubTitle}>
                    <span>
                      Sign in or{' '}
                      <a href="/signup">
                        <b>Get Started</b>
                      </a>
                    </span>
                  </div> */}
                  <div className={styles.AccessEmail}>
                    <input
                      type="text"
                      id="email"
                      value={this.state.email}
                      style={{
                        border: this.state.inCorrectEmail && '2px solid red',
                      }}
                      onChange={this.handleEmail}
                      required
                    />
                    <label>Email</label>
                  </div>
                  <div
                    className={styles.AlertEmail}
                    style={{
                      display: this.state.inCorrectEmail ? 'block' : 'none',
                    }}
                  >
                    <p>Please enter a valid email address.</p>
                  </div>
                  <div className={styles.AccessPassword}>
                    <input
                      type="password"
                      id="password"
                      value={this.state.password}
                      onChange={this.handlePassword}
                      required
                    />
                    <label>Password</label>
                  </div>
                  <div className={styles.AccessPolicy}>
                    <span>
                      By continuing, I accept Kidstory's{' '}
                      <a
                        href="https://www.kidstory.life/terms-of-use"
                        target="_blank"
                      >
                        Terms & Conditions
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://www.kidstory.life/privacy"
                        target="_blank"
                      >
                        Privacy Policy.
                      </a>
                    </span>
                    <span
                      className={styles.ForgotPassword}
                      onClick={this.handleShowModal}
                    >
                      Forgot password?
                    </span>
                  </div>
                  <button className={styles.Submit} type="submit">
                    Login
                  </button>
                </form>
              </div>
              <div className={styles.SignupLab}>
                <span>Or sign up with:</span>
              </div>
              <div className={styles.SocialLogin}>
                <ul>
                  <li onClick={this.handleAppleSignin}>
                    <AppleIcon />
                  </li>
                  <li onClick={this.handleFacebookSignin}>
                    <FacebookIcon />
                  </li>
                  <li onClick={this.handleGoogleSignin}>
                    <GoogleIcon />
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.GroupContent}>
              <img src={GroupImage} alt="GroupImage" />
            </div>
          </div>
          <div className={styles.SpinnerContent}>
            <MoonLoader
              color={'#404A7E'}
              loading={this.state.loading}
              css={Spinner}
              size={60}
            />
          </div>
          <Modal
            show={this.state.showModal}
            modalClosed={this.handleHideModal}
            modalType={'forgot'}
          >
            <ForgotPassword modalClosed={this.handleHideModal} />
          </Modal>
        </section>
        <Footer />
      </div>
    )
  }
}

export default LoginSuccess
