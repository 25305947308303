import React from 'react'

import styles from './Header.module.css'
import Logo from '../UI/Logo/Logo'
import AppStore from '../UI/Buttons/AppStore/AppStore'

const header = (props) => {
  return (
    <header className={styles.Header}>
      <div className={styles.Container}>
        <div className={styles.Logo}>
          <Logo />
        </div>
        <nav className={styles.AppStoreButton}>
          <AppStore />
        </nav>
      </div>
    </header>
  )
}

export default header
