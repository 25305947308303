import React, { Component } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import PrivateRoute from './helpers/PrivateRoute'
import PublicRoute from './helpers/PublicRoute'

import Signup from './containers/Signup/Signup'
import Login from './containers/Login/Login'
import LoginSuccess from './containers/Login/LoginSuccess'
import Life from './containers/Life/Life'
import Invite from './containers/Invite/Invite'
import ResetPassword from './containers/ResetPassword/ResetPassword'

class App extends Component {
  render() {
    return (
      <Switch>
        <PublicRoute
          restricted={false}
          path="/signup"
          exact
          component={Signup}
        />
        <PublicRoute restricted={true} path="/" exact component={Login} />
        <PublicRoute
          restricted={true}
          path="/login-reset"
          exact
          component={LoginSuccess}
        />
        <Route path="/invite/:id" component={Invite} />
        <Route path="/reset" component={ResetPassword} />
        <PrivateRoute exact path="/life" component={Life} />
        <Route render={() => <h1>Page Not Found!</h1>} />
      </Switch>
    )
  }
}

export default App
