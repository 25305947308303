import React from 'react'

import styles from './Logo.module.css'
import mainLogo from '../../../assets/images/logo_white.png'

const logo = (props) => {
  return (
    <a href="/" className={styles.Logo}>
      <img src={mainLogo} alt="KidStory" />
    </a>
  )
}

export default logo
