import React, { Component } from 'react'

import MoonLoader from 'react-spinners/MoonLoader'
import { Spinner } from '../../../../components/Variables/Variables'
import styles from './ChangePassword.module.css'
import CloseIcon from '../../../../assets/images/close.jpg'
import firebase from '../../../../firebase/Firebase'

class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      inCorrectPassword: false,
      loading: false,
      changedSuccess: false,
    }
  }

  componentDidMount() {
    this.setState({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      changedSuccess: false,
    })
  }

  handleCurrentPassword = (event) => {
    this.setState({
      currentPassword: event.target.value,
    })
  }

  handleNewPassword = (event) => {
    this.setState({
      newPassword: event.target.value,
    })
  }

  handleConfirmPassword = (event) => {
    this.setState({
      confirmPassword: event.target.value,
      inCorrectPassword: false,
    })
  }

  handleComparePassword = () => {
    if (this.state.newPassword === this.state.confirmPassword) {
      return true
    } else {
      return false
    }
  }

  reauthenticate = (currentPassword) => {
    let user = firebase.auth().currentUser
    let cred = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    )
    return user.reauthenticateWithCredential(cred)
  }

  handleSubmit = () => {
    if (this.handleComparePassword()) {
      this.setState({
        loading: true,
      })

      this.reauthenticate(this.state.currentPassword)
        .then(() => {
          let user = firebase.auth().currentUser
          user
            .updatePassword(this.state.newPassword)
            .then(() => {
              this.setState({
                changedSuccess: true,
              })
            })
            .catch((error) => {
              alert(error)
            })
        })
        .catch((error) => {
          alert(error)
        })

      this.setState({
        loading: false,
      })
    } else {
      this.setState({
        inCorrectPassword: true,
      })
      alert('Please check your re-enter password again.')
    }
  }

  handleClose = () => {
    this.setState({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      changedSuccess: false,
    })
    this.props.modalClosed()
  }

  render() {
    return (
      <div className={styles.Container}>
        <div className={styles.HeaderContent}>
          <span className={styles.HeaderTitle}>Change Password</span>
          <img
            className={styles.Close}
            src={CloseIcon}
            onClick={this.handleClose}
          />
        </div>
        <div className={styles.MainContent}>
          <p className={styles.InfoTitle}>
            {!this.state.changedSuccess
              ? 'For an account created with'
              : 'Your password was changed successfully.'}
          </p>
          <p className={styles.Email}>
            {!this.state.changedSuccess && localStorage.getItem('userEmail')}
          </p>
          {!this.state.changedSuccess ? (
            <div>
              <div className={styles.AccessEmail}>
                <input
                  type="password"
                  id="currentPassword"
                  value={this.state.currentPassword}
                  onChange={this.handleCurrentPassword}
                  required
                />
                <label>Current Password</label>
              </div>
              <div className={styles.AccessPassword}>
                <input
                  type="password"
                  id="newPassword"
                  value={this.state.newPassword}
                  onChange={this.handleNewPassword}
                  required
                />
                <label>New password</label>
              </div>
              <div className={styles.AccessPassword}>
                <input
                  type="password"
                  id="rePassword"
                  style={{
                    border: this.state.inCorrectPassword && '2px solid red',
                  }}
                  value={this.state.confirmPassword}
                  onChange={this.handleConfirmPassword}
                  required
                />
                <label>Re-enter password</label>
              </div>
              <button
                className={styles.Button}
                onClick={this.handleSubmit}
                type="submit"
              >
                Change Password
              </button>
            </div>
          ) : (
            <button
              className={styles.Button}
              onClick={this.handleClose}
              type="submit"
            >
              Ok
            </button>
          )}
        </div>
        <div className={styles.SpinnerContent}>
          <MoonLoader
            color={'#404A7E'}
            loading={this.state.loading}
            css={Spinner}
            size={60}
          />
        </div>
      </div>
    )
  }
}

export default ChangePassword
