import React, { useState, useEffect } from 'react'
import styles from './ResetPassword.module.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { auth } from '../../firebase/Firebase'
import Modal from '../../components/UI/Modal/Modal'
import NewPassword from '../../components/UI/Modal/NewPassword/NewPassword'

const ResetPassword = (props) => {
  const [passCode, setPassCode] = useState('')
  const [password, setPassword] = useState('')
  const [repassword, setRepassword] = useState('')
  const [emptyPassword, setEmptyPassword] = useState(false)
  const [emptyRepassword, setEmptyRepassword] = useState(false)
  const [matchPassword, setMatchPassword] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showTitle, setShowTitle] = useState('')
  const [showResult, setShowResult] = useState('')
  const [sentSuccess, setSentSuccess] = useState(false)

  useEffect(() => {
    let code = props.location.search.split('oobCode=').pop().split('&')[0]
    setPassCode(code)
    props.history.push('/reset')
  }, [])

  const handlePassword = (e) => {
    setPassword(e.target.value)
    setEmptyPassword(false)
    setMatchPassword(false)
    setErrorMessage('')
  }

  const handleRepassword = (e) => {
    setRepassword(e.target.value)
    setEmptyRepassword(false)
    setMatchPassword(false)
    setErrorMessage('')
  }

  const handleComparePassword = () => {
    if (password === repassword) {
      return true
    } else {
      return false
    }
  }

  const handleConfirm = () => {
    if (!password) {
      setEmptyPassword(true)
    } else if (!repassword) {
      setEmptyRepassword(true)
    } else if (!handleComparePassword()) {
      setMatchPassword(true)
    } else {
      auth
        .confirmPasswordReset(passCode, password)
        .then(function () {
          setShowTitle('Setting new password info.')
          setShowResult('Your new password was set successfully.')
          setSentSuccess(true)
          setShowModal(true)
        })
        .catch((error) => {
          setShowTitle('Setting new password error.')
          setShowResult(error.message)
          setSentSuccess(false)
          setShowModal(true)
        })
    }
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const handleSuccess = () => {
    setShowModal(false)
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      setTimeout(function () {
        window.location = 'kidstory://kidstory.link?service=reset'
      }, 25)
        
      window.location = 'https://app.kidstory.life/login-reset'
    } else {
      // false for not mobile device
      props.history.push('/login-reset')
    }
  }

  return (
    <div>
      <Header />
      <section className={styles.MainConent}>
        <div className={styles.Container}>
          <div className={styles.TitleContent}>
            <p>Please enter new password.</p>
          </div>
          <div className={styles.PasswordContent}>
            <input
              type="password"
              value={password}
              onChange={(e) => handlePassword(e)}
              style={{
                border: emptyPassword ? '1px solid #aa0000' : 'none',
              }}
              required
            />
            <label>New password</label>
            <p
              className={styles.WarningContent}
              style={{
                display: emptyPassword ? 'block' : 'none',
              }}
            >
              Please enter new password.
            </p>
          </div>
          <div className={styles.PasswordContent}>
            <input
              type="password"
              id="re-password"
              value={repassword}
              onChange={(e) => handleRepassword(e)}
              style={{
                border: emptyRepassword ? '1px solid #aa0000' : 'none',
              }}
              required
            />
            <label>Re-enter password</label>
            <p
              className={styles.WarningContent}
              style={{
                display: emptyRepassword ? 'block' : 'none',
              }}
            >
              Please enter re-enter password.
            </p>
            <p
              className={styles.WarningContent}
              style={{
                display: matchPassword ? 'block' : 'none',
              }}
            >
              Password did not match, please try again.
            </p>
            <p
              className={styles.WarningContent}
              style={{
                display: errorMessage ? 'block' : 'none',
              }}
            >
              {errorMessage}
            </p>
          </div>
          <div className={styles.ButtonContent}>
            <button
              className={styles.Submit}
              type="submit"
              onClick={handleConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
        <Modal show={showModal} modalClosed={handleClose} modalType={'forgot'}>
          <NewPassword
            handleClose={handleClose}
            handleSuccess={handleSuccess}
            showTitle={showTitle}
            showResult={showResult}
            sentSuccess={sentSuccess}
          />
        </Modal>
      </section>
      <Footer />
    </div>
  )
}

export default ResetPassword
