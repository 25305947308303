import React, { Component } from 'react'

import { db } from '../../firebase/Firebase'
import styles from './LeftSidebar.module.css'

import ArrowOpen from '../../assets/images/arrow_open.png'
import ArrowClose from '../../assets/images/arrow_close.png'
import ChildIcon from '../../assets/images/child.png'
import CheckIcon from '../../assets/images/check.png'

let parentData = []
let profileData = []
let selectedUserId = ''

class LeftSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dateTimeContent: true,
      timelineList: [],
      timelineHeight: 80,
      childrenContent: false,
      childrenHeight: 0,
      selectedChildName: '',
      selectedChildPic: '',
      selectedBirthday: '',
      numberOfKids: '',
    }
  }

  componentDidMount() {
    this.getProfiles()
  }

  getProfiles = async () => {
    const currentUserId = localStorage.getItem('userId')
    const currentYear = new Date().getFullYear()
    let invitedUserIdList = []
    let checkFirstChild = true
    let timelineLength = ''

    await db
      .ref('invite/')
      .once('value')
      .then(function (snapshot) {
        snapshot.forEach((snap) => {
          if (currentUserId == snap.val().invitedUser) {
            invitedUserIdList.push(snap.val().from)
          }
        })
      })
      .catch((error) => {
        console.log('Get Inviters lists error', error.message)
      })

    // await db
    //   .ref('account/' + currentUserId)
    //   .once('value')
    //   .then(function (snapshot) {
    //     invitedUserIdList = snapshot.val().invite.split(',')
    //   })
    //   .catch((error) => {
    //     console.log('Get Inviters lists error', error.message)
    //   })

    // if (!invitedUserIdList) {
    //   return true
    // }

    await invitedUserIdList.forEach(async (invitedUserId) => {
      let parentName = ''
      let parentEmail = ''
      let childImage = []
      let childBirthdays = []
      let selectedBirthday = ''

      await db
        .ref('account/' + invitedUserId)
        .once('value', (snapshot) => {
          if (snapshot.exists()) {
            parentName = snapshot.val().firstName && snapshot.val().firstName
            parentEmail = snapshot.val().email && snapshot.val().email
          }
        })
        .catch((error) => {
          console.log('Get sender name error', error.message)
        })

      await db
        .ref('profiles/' + invitedUserId)
        .once('value', (snapshot) => {
          snapshot.forEach((snap) => {
            childImage.push(snap.val().pic)
            childBirthdays.push(
              parseInt(
                snap
                  .val()
                  .birthday.substring(
                    snap.val().birthday.length - 4,
                    snap.val().birthday.length
                  ),
                10
              )
            )
          })
        })
        .catch((error) => {
          alert(error.message)
          console.log('profile get error: ', error.message)
        })

      selectedBirthday = Math.min.apply(Math, childBirthdays)

      profileData.push({
        birthday: selectedBirthday,
        email: parentEmail,
        kid: 'All',
        pic: childImage[0],
        selected: checkFirstChild,
        type: 'AllChild',
      })

      if (checkFirstChild) {
        this.setState({
          selectedChildName: 'All',
          selectedChildPic: childImage[0],
        })

        let timelineList = []
        timelineList.push({
          year: 'All',
          state: true,
          arts: '',
        })

        for (let i = currentYear; i >= selectedBirthday; i--) {
          timelineList.push({
            year: i,
            state: false,
            arts: '',
          })
        }

        this.setState({
          timelineList: timelineList,
          timelineHeight: timelineLength,
        })
        selectedUserId = invitedUserId
        this.getNumberOfContents('All', selectedBirthday, invitedUserId)
        this.props.selectChild(invitedUserId, 'All', 'All')
      }

      checkFirstChild = false

      await db
        .ref('profiles/' + invitedUserId)
        .once('value', (snapshot) => {
          snapshot.forEach((snap) => {
            let birthday = snap.val().birthday
              ? parseInt(
                  snap
                    .val()
                    .birthday.substring(
                      snap.val().birthday.length - 4,
                      snap.val().birthday.length
                    ),
                  10
                )
              : 2008
            profileData.push({
              birthday: birthday,
              email: snap.val().email,
              kid: snap.val().kid,
              pic: snap.val().pic,
              selected: false,
              type: 'Child',
            })
          })
        })
        .catch((error) => {
          alert(error.message)
          console.log('profile get error: ', error.message)
        })

      parentData.push({
        email: parentEmail,
        username: parentName,
        invitedUserId: invitedUserId,
      })
      let childLength = profileData.length * 40 + parentData.length * 55 + 20
      this.setState({
        childrenHeight: childLength,
      })
    })
  }

  getNumberOfContents = async (childName, selectedBirthday, invitedUserId) => {
    let currentYear = new Date().getFullYear()
    let betweenYear = currentYear - selectedBirthday
    let numberOfKids = []
    let allNumbers = 0
    const categoryNames = ['Activities', 'Favorites', 'Firsts', 'School']
    let allKidNames = []

    for (let i = 0; i <= betweenYear; i++) {
      numberOfKids[i] = 0
    }

    await db
      .ref('profiles/' + invitedUserId)
      .once('value', function (snapshot) {
        snapshot.forEach(function (childSnapshot) {
          allKidNames.push(childSnapshot.val().kid)
        })
      })
      .catch((error) => {
        console.log('HandleProfiles for invite error', error.message)
      })

    await db.ref('images/' + invitedUserId).once('value', (snapshot) => {
      let allImages = []
      snapshot.forEach((snap) => {
        allImages.push(snap.val())
      })

      allImages.map((item, index) => {
        if (this.state.selectedChildName === 'All') {
          allNumbers++
          for (let i = 0; i <= betweenYear; i++) {
            if (item.dateTaken && item.dateTaken.includes(currentYear - i)) {
              numberOfKids[i] += 1
            }
          }
        } else {
          if (item.kid === this.state.selectedChildName) {
            allNumbers++
            for (let i = 0; i <= betweenYear; i++) {
              if (item.dateTaken && item.dateTaken.includes(currentYear - i)) {
                numberOfKids[i] += 1
              }
            }
          }
        }
      })
    })

    await db.ref('art/' + invitedUserId).once('value', (snapshot) => {
      let allArts = []
      snapshot.forEach((snap) => {
        allArts.push(snap.val())
      })

      allArts.map((item, index) => {
        if (this.state.selectedChildName === 'All') {
          allNumbers++
          for (let i = 0; i <= betweenYear; i++) {
            if (item.dateTaken && item.dateTaken.includes(currentYear - i)) {
              numberOfKids[i] += 1
            }
          }
        } else {
          if (item.kid === this.state.selectedChildName) {
            allNumbers++
            for (let i = 0; i <= betweenYear; i++) {
              if (item.dateTaken && item.dateTaken.includes(currentYear - i)) {
                numberOfKids[i] += 1
              }
            }
          }
        }
      })
    })

    await db.ref('video/' + invitedUserId).once('value', (snapshot) => {
      let allVideos = []
      snapshot.forEach((snap) => {
        allVideos.push(snap.val())
      })

      allVideos.map((item, index) => {
        if (this.state.selectedChildName === 'All') {
          allNumbers++
          for (let i = 0; i <= betweenYear; i++) {
            if (item.dateTaken && item.dateTaken.includes(currentYear - i)) {
              numberOfKids[i] += 1
            }
          }
        } else {
          if (item.kid === this.state.selectedChildName) {
            allNumbers++
            for (let i = 0; i <= betweenYear; i++) {
              if (item.dateTaken && item.dateTaken.includes(currentYear - i)) {
                numberOfKids[i] += 1
              }
            }
          }
        }
      })
    })

    await db
      .ref('categories/' + invitedUserId)
      .once('value', async (snapshot) => {
        if (childName === 'All') {
          allKidNames.map((allKidName) => {
            categoryNames.map((categoryName) => {
              snapshot
                .child(allKidName)
                .child(categoryName)
                .forEach((item) => {
                  allNumbers++
                  for (let i = 0; i <= betweenYear; i++) {
                    if (
                      item.val().dateTime &&
                      item.val().dateTime.includes(currentYear - i)
                    ) {
                      numberOfKids[i] += 1
                    }
                  }
                })
            })
          })
        } else {
          categoryNames.map((categoryName, index) => {
            snapshot
              .child(childName)
              .child(categoryName)
              .forEach((item, key) => {
                allNumbers++
                for (let i = 0; i <= betweenYear; i++) {
                  if (
                    item.val().dateTime &&
                    item.val().dateTime.includes(currentYear - i)
                  ) {
                    numberOfKids[i] += 1
                  }
                }
              })
          })
        }
      })

    let tempTimeLine = [...this.state.timelineList]

    for (let i = 0; i < tempTimeLine.length; i++) {
      if (i == 0) {
        tempTimeLine[i].arts = allNumbers
      } else {
        tempTimeLine[i].arts = numberOfKids[i - 1]
      }
    }

    this.setState({
      timelineList: tempTimeLine,
    })
  }

  handleChildrenList = () => {
    this.setState({
      childrenContent: !this.state.childrenContent,
    })
  }

  handleTimeLine = () => {
    this.setState({
      dateTimeContent: !this.state.dateTimeContent,
    })
  }

  handleTimelines = (index) => {
    let timeline = [...this.state.timelineList]
    let selectedYear = timeline[index].year
    for (let i = 0; i < timeline.length; i++) {
      if (i === index) {
        timeline[i].state = true
      } else {
        timeline[i].state = false
      }
    }
    this.setState({
      timelineList: timeline,
    })
    this.props.selectChild(
      selectedUserId,
      this.state.selectedChildName,
      selectedYear
    )
  }

  handleChildClicked = async (child, invitedUserId, event) => {
    event.preventDefault()
    let currentYear = new Date().getFullYear()
    let timelineLength = ''
    selectedUserId = invitedUserId
    profileData.forEach((item) => {
      if (item.email === child.email && item.kid === child.kid) {
        item.selected = true
      } else {
        item.selected = false
      }
    })

    let timelineList = []
    timelineList.push({ year: 'All', state: true, arts: '' })

    for (let i = currentYear; i >= child.birthday; i--) {
      timelineList.push({
        year: i,
        state: false,
        arts: '',
      })
    }

    this.getNumberOfContents(child.kid, child.birthday, invitedUserId)
    this.props.selectChild(invitedUserId, child.kid, 'All')

    this.setState({
      childrenContent: false,
      selectedChildName: child.kid,
      selectedChildPic: child.pic,
      timelineList: timelineList,
      timelineHeight: timelineLength,
    })
  }

  getChildrenList = (item, index) => {
    let childInfo = []
    let childPic = []

    profileData.forEach((child, childIndex) => {
      let checkIcon = ''
      const childStyle = [styles.UserImage, styles.AllChildImage]
      if (child.selected === true) {
        checkIcon = <img className={styles.CheckImage} src={CheckIcon} />
      }

      if (child.email == item.email) {
        childPic.push(child.pic)
        childInfo.push(
          <div
            className={styles.ChildList}
            key={childIndex}
            onClick={(e) =>
              this.handleChildClicked(child, item.invitedUserId, e)
            }
          >
            <div
              className={
                child.type != 'AllChild'
                  ? styles.UserImage
                  : childStyle.join(' ')
              }
            >
              <img src={child.pic ? child.pic : ChildIcon} />
            </div>
            <div className={styles.UserName}>
              <span>{child.kid}</span>
            </div>
            {checkIcon}
          </div>
        )
      }
    })

    return (
      <div key={index}>
        <div className={styles.ParentName}>
          <p>{item.username}'s Children</p>
        </div>
        {childInfo}
      </div>
    )
  }

  getTimeline = (item, index) => {
    return (
      <li
        key={index}
        onClick={() => this.handleTimelines(index)}
        className={
          this.state.timelineList[index].state
            ? styles.SetTimeline
            : styles.UnsetTimeline
        }
      >
        <span className={styles.Year}>{item.year}</span>
        <p className={styles.Numbers}>({item.arts})</p>
      </li>
    )
  }

  render() {
    const openDateTime = [styles.DateTime, styles.Open]
    const closedDateTime = [styles.DateTime, styles.Close]
    const openChildrenList = [styles.ChildrenList, styles.OpenChildList]
    const closedChildrenList = [styles.ChildrenList, styles.CloseChildList]
    const childStyle = [styles.UserImage, styles.AllChildImage]

    return (
      <div
        className={styles.LeftSidebar}
        style={{
          display: this.props.show ? 'block' : 'none',
        }}
      >
        <div className={styles.UserContent} onClick={this.handleChildrenList}>
          <div
            className={
              this.state.selectedChildName != 'All'
                ? styles.UserImage
                : childStyle.join(' ')
            }
          >
            <img
              src={
                this.state.selectedChildPic
                  ? this.state.selectedChildPic
                  : ChildIcon
              }
            />
          </div>
          <div className={styles.UserName}>
            <span>{this.state.selectedChildName}</span>
          </div>
          <div className={styles.DropIcon}>
            <img src={this.state.childrenContent ? ArrowOpen : ArrowClose} />
          </div>
        </div>
        <div
          className={
            this.state.childrenContent
              ? openChildrenList.join(' ')
              : closedChildrenList.join(' ')
          }
          style={{
            height: this.state.childrenContent ? this.state.childrenHeight : 0,
          }}
        >
          {parentData &&
            parentData.map((item, index) => this.getChildrenList(item, index))}
        </div>
        <div
          className={
            this.state.dateTimeContent
              ? openDateTime.join(' ')
              : closedDateTime.join(' ')
          }
          style={{
            height: this.state.dateTimeContent && this.state.timelineHeight,
          }}
        >
          <div className={styles.TimeLine} onClick={this.handleTimeLine}>
            <p>Timeline</p>
            <div className={styles.TimelineDropIcon}>
              <img src={this.state.dateTimeContent ? ArrowOpen : ArrowClose} />
            </div>
          </div>
          <div className={styles.YearLines}>
            <ul>
              {this.state.timelineList.map((item, index) =>
                this.getTimeline(item, index)
              )}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default LeftSidebar
