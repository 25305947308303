import React from 'react'

import styles from './Hamburger.module.css'

const hamburger = (props) => {
  return (
    <div className={styles.MenuToggle}>
      <input type="checkbox" />
      <span className={styles.Span1}></span>
      <span className={styles.Span2}></span>
      <span className={styles.Span3}></span>
    </div>
  )
}

export default hamburger
