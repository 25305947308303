import React, { Component } from 'react'

import styles from './ForgotPassword.module.css'
import CloseIcon from '../../../../assets/images/close.jpg'
import { auth } from '../../../../firebase/Firebase'

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      inCorrectEmail: false,
      sentSuccess: false,
    }
  }

  componentWillUnmount() {
    this.setState({
      sentSuccess: false,
    })
  }

  handleEmail = (event) => {
    this.setState({
      email: event.target.value,
      inCorrectEmail: false,
    })
  }

  handleContinue = () => {
    this.setState({
      sentSuccess: !this.state.sentSuccess,
    })
  }

  handleClose = () => {
    this.setState({
      email: '',
      sentSuccess: false,
    })
    this.props.modalClosed()
  }

  handleSubmit = () => {
    auth
      .sendPasswordResetEmail(this.state.email)
      .then(() => {
        this.setState({
          sentSuccess: true,
        })
      })
      .catch((error) => {
        this.setState({
          inCorrectEmail: true,
        })
        alert(error.message)
      })
  }

  render() {
    return (
      <div className={styles.Container}>
        <div className={styles.HeaderContent}>
          <span className={styles.HeaderTitle}>Forgot Password</span>
          <img
            className={styles.Close}
            src={CloseIcon}
            onClick={this.handleClose}
          />
        </div>
        <div className={styles.MainContent}>
          <p className={styles.InfoTitle}>
            {this.state.sentSuccess
              ? 'Password reset link was sent to your email address.'
              : 'Please enter your email address to reset your password.'}
          </p>
          {!this.state.sentSuccess && (
            <div className={styles.AccessEmail}>
              <input
                type="text"
                value={this.state.email}
                style={{ border: this.state.inCorrectEmail && '2px solid red' }}
                onChange={this.handleEmail}
                required
              />
              <label>Email</label>
            </div>
          )}
          {this.state.sentSuccess ? (
            <button className={styles.Button} onClick={this.handleClose}>
              Ok
            </button>
          ) : (
            <button className={styles.Button} onClick={this.handleSubmit}>
              Continue
            </button>
          )}
        </div>
      </div>
    )
  }
}

export default ForgotPassword
