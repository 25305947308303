import React, { Component } from 'react'

import styles from './Apple.module.css'
import AppleIcon from '../../../../assets/images/apple_icon.svg'

const apple = (props) => {
  return <img className={styles.Button} src={AppleIcon} alt="Apple" />
}

export default apple
