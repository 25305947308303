import React, { Component } from 'react'

import styles from './Facebook.module.css'
import FacebookIcon from '../../../../assets/images/facebook_icon.svg'

const facebook = (props) => {
  return <img className={styles.Button} src={FacebookIcon} alt="Facebook" />
}

export default facebook
