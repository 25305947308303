import React, { Component } from 'react'

import styles from './Contact.module.css'

import ArrowClose from './../../../assets/images/arrow_close.png'

class Contact extends Component {
  render() {
    return (
      <div className={styles.Contact}>
        <a className={styles.SubTitle} href="mailto:info@kidstory.life">
          <p>Contact us</p>
          <img src={ArrowClose} />
        </a>
      </div>
    )
  }
}

export default Contact
