import React, { Component } from 'react'

import { ReactVideo } from 'reactjs-media'
import MoonLoader from 'react-spinners/MoonLoader'
import Comment from '../../components/UI/Comment/Comment'
import { Spinner } from '../../components/Variables/Variables'
import { db } from '../../firebase/Firebase'
import styles from './Life.module.css'
import MainHeader from '../../components/MainHeader/MainHeader'
import LeftSidebar from '../../components/LeftSidebar/LeftSidebar'
import RightSidebar from '../../components/RightSidebar/RightSidebar'
import AppStore from '../../components/UI/Buttons/AppStore/AppStore'
import Modal from '../../components/UI/Modal/Modal'
import ChangePassword from '../../components/UI/Modal/ChangePassword/ChangePassword'
import LikeIcon from '../../assets/images/like.png'
import CommentIcon from '../../assets/images/comment.png'
import LikeCommentIcon from '../../assets/images/like_comment.png'

class Life extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hamburger: true,
      windowWidth: window.innerWidth,
      webSize: true,
      userProfiles: [],
      userEmail: '',
      firstName: '',
      lastName: '',
      imagesData: [],
      stateOfLoadData: false,
      selectedChildName: '',
      childYear: 'All',
      loading: false,
      showModal: false,
      invitedUserId: '',
    }
    const sideBars = []
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.setState({
      userEmail: localStorage.getItem('userEmail'),
      stateOfLoadData: true,
    })
    this.getUserProfile()
    this.forceReloadPage()
  }

  forceReloadPage = () => {
    const reloadCount = sessionStorage.getItem('reloadCount')
    if (reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1))
      window.location.reload()
    } else {
      sessionStorage.removeItem('reloadCount')
    }
  }

  getUserProfile = async () => {
    this.setState({
      loading: true,
    })

    this.setState({
      firstName: localStorage.getItem('firstName')
        ? localStorage.getItem('firstName')
        : '',
      lastName: localStorage.getItem('lastName')
        ? localStorage.getItem('lastName')
        : '',
    })

    this.setState({
      loading: false,
    })
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.handleResize)
  }

  handleSelectedChild = async (invitedUserId, childName, childYear) => {
    const selectedChildYear = childYear === 'All' ? ' ' : childYear
    const categoryNames = ['Activities', 'Favorites', 'Firsts', 'School']
    let allKidNames = []

    this.setState({
      selectedChildName: childName,
      childYear: childYear,
      invitedUserId: invitedUserId,
      loading: true,
    })

    await db
      .ref('profiles/' + invitedUserId)
      .once('value', function (snapshot) {
        snapshot.forEach(function (childSnapshot) {
          allKidNames.push(childSnapshot.val().kid)
        })
      })
      .catch((error) => {
        console.log('HandleProfiles for invite error', error.message)
      })

    await db.ref('art/' + invitedUserId).once('value', (snapshot) => {
      let allNotes = []
      snapshot.forEach((snap) => {
        if (childName === 'All') {
          if (
            snap.val().dateTaken &&
            snap.val().dateTaken.includes(selectedChildYear)
          ) {
            allNotes.push({
              ...snap.val(),
              userId: invitedUserId,
              category: 'Art',
            })
          }
        } else {
          if (
            snap.val().kid === childName &&
            snap.val().dateTaken.includes(selectedChildYear)
          ) {
            allNotes.push({ ...snap.val(), userId: invitedUserId })
          }
        }
      })
      localStorage.setItem('art', JSON.stringify(allNotes))
    })

    await db.ref('images/' + invitedUserId).once('value', (snapshot) => {
      let allNotes = []
      snapshot.forEach((snap) => {
        if (childName === 'All') {
          if (
            snap.val().dateTaken &&
            snap.val().dateTaken.includes(selectedChildYear)
          ) {
            allNotes.push({
              ...snap.val(),
              userId: invitedUserId,
              category: 'Images',
            })
          }
        } else {
          if (
            snap.val().kid === childName &&
            snap.val().dateTaken.includes(selectedChildYear)
          ) {
            allNotes.push({ ...snap.val(), userId: invitedUserId })
          }
        }
      })
      localStorage.setItem('images', JSON.stringify(allNotes))
    })

    await db.ref('video/' + invitedUserId).once('value', (snapshot) => {
      let allNotes = []
      snapshot.forEach((snap) => {
        if (childName === 'All') {
          if (
            snap.val().dateTaken &&
            snap.val().dateTaken.includes(selectedChildYear)
          ) {
            allNotes.push({
              ...snap.val(),
              userId: invitedUserId,
              category: 'Video',
            })
          }
        } else {
          if (
            snap.val().kid === childName &&
            snap.val().dateTaken.includes(selectedChildYear)
          ) {
            allNotes.push({ ...snap.val(), userId: invitedUserId })
          }
        }
      })
      localStorage.setItem('video', JSON.stringify(allNotes))
    })

    await db
      .ref('categories/' + invitedUserId)
      .once('value', async (snapshot) => {
        let allNotes = []
        if (childName === 'All') {
          allKidNames.map((allKidName) => {
            categoryNames.map((categoryName) => {
              snapshot
                .child(allKidName)
                .child(categoryName)
                .forEach((item) => {
                  allNotes.push({
                    dateTaken: item.val().dateTime,
                    key: item.val().key,
                    kid: allKidName,
                    photoDescription: item.val().description,
                    photoTitle: item.val().value,
                    url: item.val().photoURL,
                    urlThumbnail: item.val().urlThumbnail,
                    videoMode: item.val().videoMode,
                    userId: invitedUserId,
                    category: item.val().category,
                  })
                })
            })
          })
        } else {
          categoryNames.map((categoryName) => {
            snapshot
              .child(childName)
              .child(categoryName)
              .forEach((item) => {
                allNotes.push({
                  dateTaken: item.val().dateTime,
                  key: item.val().key,
                  kid: childName,
                  photoDescription: item.val().description,
                  photoTitle: item.val().value,
                  url: item.val().photoURL,
                  urlThumbnail: item.val().urlThumbnail,
                  videoMode: item.val().videoMode,
                  userId: invitedUserId,
                  category: item.val().category,
                })
              })
          })
        }
        localStorage.setItem('categories', JSON.stringify(allNotes))
      })

    await db.ref('comment/').once('value', (snapshot) => {
      let allNotes = []
      snapshot.forEach((snap) => {
        allNotes.push(snap.val())
      })
      localStorage.setItem('comment', JSON.stringify(allNotes))
    })

    this.setState({
      stateOfLoadData: true,
      loading: false,
    })
  }

  handlePostComment = async () => {
    const childName = this.state.selectedChildName
    const childYear = this.state.childYear
    const invitedUserId = this.state.invitedUserId
    this.handleSelectedChild(invitedUserId, childName, childYear)
  }

  handleResize = (event) => {
    this.setState({ windowWidth: window.innerWidth })
    if (this.state.windowWidth <= 1100) {
      this.setState({
        webSize: false,
      })
    } else {
      this.setState({
        hamburger: true,
        webSize: true,
      })
    }
  }

  handleMainContents = () => {
    const imagesContents = JSON.parse(localStorage.getItem('images'))
    const artContents = JSON.parse(localStorage.getItem('art'))
    const videoContents = JSON.parse(localStorage.getItem('video'))
    const comments = JSON.parse(localStorage.getItem('comment'))
    const categories = JSON.parse(localStorage.getItem('categories'))
    let mainContents = []

    imagesContents &&
      imagesContents.sort(
        (a, b) => Date.parse(b.dateTaken) - Date.parse(a.dateTaken)
      )

    artContents &&
      artContents.sort(
        (a, b) => Date.parse(b.dateTaken) - Date.parse(a.dateTaken)
      )

    videoContents &&
      videoContents.sort(
        (a, b) => Date.parse(b.dateTaken) - Date.parse(a.dateTaken)
      )

    categories &&
      categories.sort(
        (a, b) => Date.parse(b.dateTaken) - Date.parse(a.dateTaken)
      )

    let dataContents = [imagesContents, artContents, videoContents, categories]

    dataContents.map((dataContent) => {
      if (dataContent) {
        dataContent.map((item, index) => {
          let newComments = []
          comments &&
            comments.map((commentItem, commentIndex) => {
              if (commentItem.category_key === item.key) {
                newComments.push(
                  <div className={styles.Comment} key={commentIndex}>
                    <div className={styles.IconContainer}>
                      <img
                        className={styles.IconContent}
                        src={
                          commentItem.type == 0
                            ? CommentIcon
                            : commentItem.type == 1
                            ? LikeIcon
                            : LikeCommentIcon
                        }
                      />
                    </div>
                    <div className={styles.ContentContainer}>
                      <div className={styles.CommentPoster}>
                        <span>
                          <b>{commentItem.username}</b>{' '}
                          {commentItem.type == 0
                            ? 'commented on a photo'
                            : commentItem.type == 1
                            ? 'liked a photo'
                            : 'liked and commented on a photo'}
                        </span>
                      </div>
                      <div className={styles.CommentContent}>
                        <p>{commentItem.value}</p>
                      </div>
                      <div className={styles.CommentDate}>
                        <span>{commentItem.date}</span>
                      </div>
                    </div>
                  </div>
                )
              }
            })

          mainContents.push(
            <div key={index} className={styles.MainContent}>
              <div className={styles.MainImage}>
                {item.videoMode && item.videoMode == '1' ? (
                  <ReactVideo
                    src={item.url}
                    poster={item.urlThumbnail}
                    primaryColor="red"
                    style={{ width: '100%' }}
                  />
                ) : (
                  <img src={item.url} style={{ width: '100%' }} />
                )}
              </div>
              <div className={styles.Quote}>
                <div className={styles.QuoteTitle}>
                  <span>{item.photoTitle}</span>
                </div>
                <div className={styles.QuoteContent}>
                  <p>{item.photoDescription}</p>
                </div>
                <div className={styles.QuoteDate}>
                  <span>{item.dateTaken}</span>
                </div>
              </div>
              <div className={styles.Comments}>
                <Comment
                  item={item}
                  firstName={this.state.firstName}
                  handleRefresh={this.handlePostComment}
                />
                {newComments}
              </div>
            </div>
          )
        })
      }
    })
    return mainContents
  }

  handleHamburger = (event) => {
    this.setState({
      hamburger: !this.state.hamburger,
    })
  }

  handleShowModal = () => {
    this.setState({
      showModal: true,
    })
  }

  handleHideModal = () => {
    this.setState({
      showModal: false,
    })
  }

  render() {
    const containerStyle = [styles.MainContainer, styles.RightContainer]
    let sideBars = ''
    let mainContents = ''

    if (this.state.stateOfLoadData) {
      mainContents = this.handleMainContents()
    }

    if (!this.state.webSize) {
      sideBars = (
        <div>
          <LeftSidebar
            show={this.state.hamburger}
            selectChild={this.handleSelectedChild}
          />
          <RightSidebar
            email={this.state.userEmail}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            showModal={this.handleShowModal}
            show={this.state.hamburger}
          />
        </div>
      )
    } else {
      sideBars = (
        <div>
          <LeftSidebar show="true" selectChild={this.handleSelectedChild} />
          <RightSidebar
            email={this.state.userEmail}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            showModal={this.handleShowModal}
          />
        </div>
      )
    }
    return (
      <div className={styles.LifeContainer}>
        <MainHeader pageName="KidStory.life" clicked={this.handleHamburger} />
        <section
          className={
            !this.state.hamburger && !this.state.webSize
              ? containerStyle.join(' ')
              : styles.MainContainer
          }
        >
          <div className={styles.Container}>
            <div className={styles.Sidebars}>
              {this.state.stateOfLoadData === true && sideBars}
            </div>
            <div
              className={styles.MainContents}
              style={{
                display: !this.state.hamburger ? 'none' : 'inline-block',
              }}
            >
              {mainContents}
              <div className={styles.EndMark}>
                <div className={styles.EndMarkLine} />
              </div>
            </div>
            <div
              className={styles.BottomInfo}
              style={{
                display:
                  !this.state.hamburger && !this.state.webSize
                    ? 'block'
                    : 'none',
              }}
            >
              <div className={styles.AppStoreButton}>
                <AppStore />
              </div>
              <div className={styles.PageName}>
                <p>KidStory.life</p>
              </div>
            </div>
          </div>
          <div className={styles.SpinnerContent}>
            <MoonLoader
              color={'#404A7E'}
              loading={this.state.loading}
              css={Spinner}
              size={60}
            />
          </div>

          <Modal
            show={this.state.showModal}
            modalClosed={this.handleHideModal}
            modalType={'change'}
          >
            <ChangePassword modalClosed={this.handleHideModal} />
          </Modal>
        </section>
      </div>
    )
  }
}

export default Life
