import React, { Component } from 'react'

import MoonLoader from 'react-spinners/MoonLoader'
import { Spinner } from '../../components/Variables/Variables'
import styles from './Signup.module.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import AppleIcon from '../../components/UI/Buttons/Apple/Apple'
import FacebookIcon from '../../components/UI/Buttons/Facebook/Facebook'
import GoogleIcon from '../../components/UI/Buttons/Google/Google'
import GroupImage from '../../assets/images/group_image.png'
import firebase, { auth, db } from '../../firebase/Firebase'

class Signup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      inCorrectEmail: false,
      inCorrectPassword: false,
      loading: false,
      userName: '',
      childName: '',
    }
  } 

  componentDidMount() {
    let userName = localStorage.getItem('userName')
      ? localStorage.getItem('userName')
      : null
    let childName = localStorage.getItem('childName')
      ? localStorage.getItem('childName')
      : null

    this.setState({
      userName: userName,
      childName: childName,
    })
  }

  handleSignup = (event) => {
    event.preventDefault()
    this.setState({
      loading: true,
    })
    this.handleComparePassword()
    const reqEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (reqEmail.test(String(this.state.email).toLowerCase())) {
      this.setState({
        inCorrectEmail: false,
      })
      if (this.handleComparePassword()) {
        auth
          .createUserWithEmailAndPassword(this.state.email, this.state.password)
          .then(async (res) => {
            const token = await Object.entries(res.user)[5][1].b
            localStorage.setItem('userId', res.user.uid)
            localStorage.setItem('userEmail', res.user.email)
            localStorage.setItem('firstName', this.state.firstName)
            localStorage.setItem('lastName', this.state.lastName)
            localStorage.setItem('token', token)
            this.handleAccount(
              res.user.uid,
              res.user.email,
              this.state.firstName,
              this.state.lastName
            )
            this.setState({
              loading: false,
            })
          })
          .catch((err) => {
            this.setState({
              loading: false,
            })
            alert(err.message)
          })
      } else {
        this.setState({
          loading: false,
        })
      }
    } else {
      this.setState({
        inCorrectEmail: true,
        loading: false,
      })
    }
  }

  handleFirstName = (event) => {
    this.setState({
      firstName: event.target.value,
    })
  }

  handleLastName = (event) => {
    this.setState({
      lastName: event.target.value,
    })
  }

  handleEmail = (event) => {
    this.setState({
      email: event.target.value,
    })
  }

  handlePassword = (event) => {
    this.setState({
      password: event.target.value,
    })
  }

  handleConfirmPassword = (event) => {
    this.setState({
      confirmPassword: event.target.value,
    })
  }

  handleComparePassword = () => {
    if (this.state.password === this.state.confirmPassword) {
      this.setState({
        inCorrectPassword: false,
      })
      return true
    } else {
      this.setState({
        inCorrectPassword: true,
      })
      return false
    }
  }

  handleAppleSignup = () => {
    this.setState({
      loading: true,
    })
    let provider = new firebase.auth.OAuthProvider('apple.com')
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        if (res.credential) {
          /** @type {firebase.auth.OAuthCredential} */
          console.log('Apple login was successful.')
          let credential = res.credential
          let user = res.user
          let accessToken = credential.accessToken
          let idToken = credential.idToken
          let firstName = res.user.displayName
            ? res.user.displayName.split(' ').slice(0, -1).join(' ')
            : ''
          let lastName = res.user.displayName
            ? res.user.displayName.split(' ').slice(-1).join(' ')
            : ''
          localStorage.setItem('userEmail', user.email)
          localStorage.setItem('userId', user.uid)
          localStorage.setItem('token', accessToken)
          this.handleAccount(res.user.uid, res.user.email, firstName, lastName)
          this.setState({
            loading: false,
          })
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        })
        alert(error.message)
      })
  }

  handleFacebookSignup = () => {
    const provider = new firebase.auth.FacebookAuthProvider()
    this.setState({
      loading: true,
    })
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        if (res.credential) {
          /** @type {firebase.auth.OAuthCredential} */
          let credential = res.credential
          let token = credential.accessToken
          let firstName = res.user.displayName
            ? res.user.displayName.split(' ').slice(0, -1).join(' ')
            : ''
          let lastName = res.user.displayName
            ? res.user.displayName.split(' ').slice(-1).join(' ')
            : ''
          localStorage.setItem('userEmail', res.user.email)
          localStorage.setItem('userId', res.user.uid)
          localStorage.setItem('token', token)
          this.handleAccount(res.user.uid, res.user.email, firstName, lastName)
          this.setState({
            loading: false,
          })
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        })
        alert(error.message)
      })
  }

  handleGoogleSignup = () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    this.setState({
      loading: true,
    })
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        if (res.credential) {
          /** @type {firebase.auth.OAuthCredential} */
          let credential = res.credential
          let token = credential.accessToken
          let firstName = res.user.displayName
            ? res.user.displayName.split(' ').slice(0, -1).join(' ')
            : ''
          let lastName = res.user.displayName
            ? res.user.displayName.split(' ').slice(-1).join(' ')
            : ''
          localStorage.setItem('userEmail', res.user.email)
          localStorage.setItem('userId', res.user.uid)
          localStorage.setItem('token', token)
          this.handleAccount(res.user.uid, res.user.email, firstName, lastName)
          this.setState({
            loading: false,
          })
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        })
        alert(error.message)
      })
  }

  handleAccount = async (uid, email, firstName, lastName) => {
    let inviteKey = localStorage.getItem('inviteKey')
      ? localStorage.getItem('inviteKey')
      : ''
    let toEmail = localStorage.getItem('toEmail')
    let checkSameUser = false
    if (email == toEmail) {
      checkSameUser = true
    }
    await db
      .ref('account/' + uid)
      .once('value')
      .then(function (snapshot) {
        if (snapshot.exists()) {
          let savedFirstName = ''
          let savedLastName = ''
          snapshot.forEach((snap) => {
            if (snap.key == 'firstName') {
              savedFirstName = snap.val()
            } else if (snap.key == 'lastName') {
              savedLastName = snap.val()
            }
          })
          if (savedFirstName != '' && savedLastName != '') {
            localStorage.setItem('firstName', savedFirstName)
            localStorage.setItem('lastName', savedLastName)
          } else {
            if (checkSameUser) {
              db.ref('account/' + uid).update({
                email: email,
                firstName: firstName === null ? '' : firstName,
                lastName: lastName === null ? '' : lastName,
                userLikeCheck: '1',
                userCommentCheck: '1',
                userBirthCheck: '1',
              })
            } else {
              db.ref('account/' + uid).update({
                email: email,
                firstName: firstName === null ? '' : firstName,
                lastName: lastName === null ? '' : lastName,
                userLikeCheck: '1',
                userCommentCheck: '1',
                userBirthCheck: '1',
              })
            }
            localStorage.setItem('firstName', firstName)
            localStorage.setItem('lastName', lastName)
          }
        } else {
          if (checkSameUser) {
            db.ref('account/' + uid).set({
              email: email,
              firstName: firstName === null ? '' : firstName,
              lastName: lastName === null ? '' : lastName,
              userLikeCheck: '1',
              userCommentCheck: '1',
              userBirthCheck: '1',
            })
          } else {
            db.ref('account/' + uid).set({
              email: email,
              firstName: firstName === null ? '' : firstName,
              lastName: lastName === null ? '' : lastName,
              userLikeCheck: '1',
              userCommentCheck: '1',
              userBirthCheck: '1',
            })
          }
          localStorage.setItem('firstName', firstName)
          localStorage.setItem('lastName', lastName)
        }
      })
      .catch((error) => {
        alert(error.message)
      })

    if (inviteKey) {
      let currentAcceptedTime = ''
      await db
        .ref('invite' + inviteKey)
        .once('value', function (snapshot) {
          currentAcceptedTime = snapshot.val().acceptedTime
        })
        .catch((error) => {
          console.log('Invite field error: ', error.message)
        })

      if (!currentAcceptedTime) {
        const date = new Date()
        let time = date.getTime()
        let timeString = time.toString()
        db.ref('invite/' + inviteKey).update({
          invitedUser: uid,
          acceptedTime: timeString,
        })
      }
    }

    localStorage.removeItem('inviteKey')
    localStorage.removeItem('userName')
    localStorage.removeItem('childName')
    this.props.history.push('/life')
  }

  render() {
    const userInfoStyle = [styles.AccessEmail, styles.UserName]
    return (
      <div>
        <Header />
        <section className={styles.MainConent}>
          <div className={styles.Container}>
            <div className={styles.AccessContent}>
              {this.state.userName ? (
                <div className={styles.AccessTitle}>
                  <h1>
                    {this.state.userName} invited you to {this.state.childName}
                    's Kidstory
                    <sup>TM</sup>
                  </h1>
                  <p>{this.state.childName}'s digital scrapbook of memories.</p>
                </div>
              ) : (
                <div className={styles.AccessTitle}>
                  <h1>Welcome to Kidstory</h1>
                </div>
              )}
              <div className={styles.AccessBox}>
                <form onSubmit={this.handleSignup}>
                  <div className={styles.AccessSubTitle}>
                    <span>
                      Get Started or{' '}
                      <a href="/">
                        <b>Sign In</b>
                      </a>
                    </span>
                  </div>
                  <div className={styles.FirstName}>
                    <input
                      type="text"
                      id="email"
                      value={this.state.firstName}
                      onChange={this.handleFirstName}
                      required
                    />
                    <label>First Name</label>
                  </div>
                  <div className={styles.LastName}>
                    <input
                      type="text"
                      id="email"
                      value={this.state.lastName}
                      onChange={this.handleLastName}
                      required
                    />
                    <label>Last Name</label>
                  </div>

                  <div className={styles.AccessEmail}>
                    <input
                      type="text"
                      id="email"
                      value={this.state.email}
                      style={{
                        border: this.state.inCorrectEmail && '2px solid red',
                      }}
                      onChange={this.handleEmail}
                      required
                    />
                    <label>Email</label>
                  </div>
                  <div
                    className={styles.AlertEmail}
                    style={{
                      display: this.state.inCorrectEmail ? 'block' : 'none',
                    }}
                  >
                    <p>Please enter a valid email address.</p>
                  </div>
                  <div className={styles.AccessPassword}>
                    <input
                      type="password"
                      id="password"
                      value={this.state.password}
                      onChange={this.handlePassword}
                      required
                    />
                    <label>Create password</label>
                  </div>
                  <div className={styles.AccessPassword}>
                    <input
                      type="password"
                      id="re-password"
                      value={this.state.confirmPassword}
                      onChange={this.handleConfirmPassword}
                      style={{
                        border: this.state.inCorrectPassword && '2px solid red',
                      }}
                      required
                    />
                    <label>Re-enter password</label>
                  </div>
                  <div
                    className={styles.AlertEmail}
                    style={{
                      display: this.state.inCorrectPassword ? 'block' : 'none',
                    }}
                  >
                    <p>Please check your re-enter password.</p>
                  </div>
                  <div className={styles.AccessPolicy}>
                    <span>
                      By continuing, I accept Kidstory's{' '}
                      <a
                        href="https://www.kidstory.life/terms-of-use"
                        target="_blank"
                      >
                        Terms & Conditions
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://www.kidstory.life/privacy"
                        target="_blank"
                      >
                        Privacy Policy.
                      </a>
                    </span>
                  </div>
                  <button className={styles.Submit} type="submit">
                    Create an account
                  </button>
                </form>
              </div>
              <div className={styles.SignupLab}>
                <span>Or sign up with:</span>
              </div>
              <div className={styles.SocialLogin}>
                <ul>
                  <li onClick={this.handleAppleSignup}>
                    <AppleIcon />
                  </li>
                  <li onClick={this.handleFacebookSignup}>
                    <FacebookIcon />
                  </li>
                  <li onClick={this.handleGoogleSignup}>
                    <GoogleIcon />
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.GroupContent}>
              <img src={GroupImage} alt="GroupImage" />
            </div>
          </div>
          <div className={styles.SpinnerContent}>
            <MoonLoader
              color={'#404A7E'}
              loading={this.state.loading}
              css={Spinner}
              size={60}
            />
          </div>
        </section>
        <Footer />
      </div>
    )
  }
}

export default Signup
