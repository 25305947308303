import React from 'react'

import styles from './InvalidInvite.module.css'
import CloseIcon from '../../../../assets/images/close.jpg'

const InvalidInvite = (props) => {
  return (
    <div className={styles.Container}>
      <div className={styles.HeaderContent}>
        <span className={styles.HeaderTitle}>{props.showTitle}</span>
        <img
          className={styles.Close}
          src={CloseIcon}
          onClick={props.handleClose}
        />
      </div>
      <div className={styles.MainContent}>
        <p className={styles.InfoTitle}>{props.showResult}</p>
        <button className={styles.Button} onClick={props.handleClose}>
          Close
        </button>
      </div>
    </div>
  )
}

export default InvalidInvite
