import React, { Component } from 'react'

import styles from './RightSidebar.module.css'

import Account from './Account/Account'
import Settings from './Settings/Settings'
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy'
import Terms from './Terms/Terms'
import Contact from './Contact/Contact'

class RightSidebar extends Component {
  render() {
    return (
      <div
        className={styles.RightSidebar}
        style={{ display: this.props.show ? 'none' : 'block' }}
      >
        <Account
          email={this.props.email}
          firstName={this.props.firstName}
          lastName={this.props.lastName}
          showChangeModal={this.props.showModal}
        />
        <Settings />
        <PrivacyPolicy />
        <Terms />
        <Contact />
      </div>
    )
  }
}

export default RightSidebar
