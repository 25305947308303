import React, { Component } from 'react'

import styles from './Terms.module.css'

import ArrowClose from './../../../assets/images/arrow_close.png'

class Terms extends Component {
  render() {
    let termsURL = 'https://www.kidstory.life/terms-of-use'
    return (
      <div className={styles.Terms}>
        <a href={termsURL} target="_blank" className={styles.SubTitle}>
          <p>Terms</p>
          <img src={ArrowClose} />
        </a>
      </div>
    )
  }
}

export default Terms
