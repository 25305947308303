import React, { Component } from 'react'

import Signup from '../Signup/Signup'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Modal from '../../components/UI/Modal/Modal'
import styles from '../ResetPassword/ResetPassword.module.css'
import { db } from '../../firebase/Firebase'
import InvalidInvite from '../../components/UI/Modal/InvalidInvite/InvalidInvite'

class Invite extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      showResult:
        'The invite code is invalid. This can happen if the code is malformed, expired, or has already been used.',
    }
  }

  componentDidMount() {
    this.handleCheckURL()
  }

  handleCheckURL = async () => {
    let str = this.props.location.pathname.replace('/invite/:', '')
    let fromUid = str.substring(
      str.indexOf('from=') + 5,
      str.lastIndexOf('&to')
    )
    let toEmail = str.substring(
      str.indexOf('&to=') + 4,
      str.lastIndexOf('&userName')
    )
    let userName = str.substring(
      str.indexOf('&userName=') + 10,
      str.lastIndexOf('&childName')
    )
    let childName = str.substring(
      str.indexOf('&childName=') + 11,
      str.lastIndexOf('&inviteKey=')
    )
    let inviteKey = str.substring(str.indexOf('&inviteKey=') + 11)
    let acceptedTime = ''

    let toUid = ''
    let checkUserExist = 0
    let inviteList = ''

    localStorage.clear();

    localStorage.setItem('userName', userName)
    localStorage.setItem('childName', childName)

    await db
      .ref('invite/' + inviteKey)
      .once('value', function (snapshot) {
        acceptedTime = snapshot.val() && snapshot.val().acceptedTime
      })
      .catch((error) => {
        console.log('Invite field error: ', error.message)
      })

    if (acceptedTime) {
      this.setState({
        showModal: true,
      })
    } else {
      localStorage.setItem('fromUid', fromUid)
      localStorage.setItem('toEmail', toEmail)
      localStorage.setItem('inviteKey', inviteKey)
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // true for mobile device
        setTimeout(function () {
          window.location = 'kidstory://kidstory.link?service=invite'
        }, 2025)
        setTimeout(function () {
          window.location = 'https://app.kidstory.life/signup'
        }, 2000)
        
      } else {
        // false for not mobile device
        setTimeout(this.props.history.push('/signup'), 2000)
      }
      
    }

    // await db
    //   .ref('account')
    //   .once('value', function (snapshot) {
    //     snapshot.forEach(function (childSnapshot) {
    //       if (childSnapshot.val().email == toEmail) {
    //         checkUserExist = 1
    //         toUid = childSnapshot.key
    //         inviteList = childSnapshot.val().invite
    //       }
    //     })
    //   })
    //   .catch((error) => {
    //     console.log('HandleAccount for invite error', error.message)
    //   })

    // if (checkUserExist == 0) {
    //   await db
    //     .ref('profiles')
    //     .once('value', function (snapshot) {
    //       snapshot.forEach(function (childSnapshot) {
    //         childSnapshot.forEach((childInfo) => {
    //           if (childInfo.val().email == toEmail) {
    //             checkUserExist = 2
    //             toUid = childSnapshot.key
    //           }
    //         })
    //       })
    //     })
    //     .catch((error) => {
    //       console.log('HandleProfiles for invite error', error.message)
    //     })
    // }

    // if (inviteList) {
    //   if (!inviteList.includes(fromUid)) {
    //     inviteList = inviteList + ',' + fromUid
    //   }
    // } else {
    //   inviteList = fromUid
    // }

    // if (checkUserExist == 1) {
    //   await db.ref('account/' + toUid).update({
    //     invite: inviteList,
    //   })
    //   setTimeout(this.props.history.push('/life'), 2000)
    // } else if (checkUserExist == 2) {
    //   await db.ref('account/' + toUid).update({
    //     email: toEmail,
    //     invite: inviteList,
    //     userBirthCheck: '1',
    //     userCommentCheck: '1',
    //     userLikeCheck: '1',
    //   })
    //   setTimeout(this.props.history.push('/life'), 2000)
    // } else {
    //   localStorage.setItem('fromUid', inviteList)
    //   localStorage.setItem('toEmail', toEmail)
    //   this.props.history.push('/signup')
    //   // setTimeout( this.props.history.push('/signup'), 1000);
    // }
  }

  handleClose = () => {
    this.setState({
      showModal: false,
    })
    localStorage.removeItem('inviteKey')
    localStorage.removeItem('userName')
    localStorage.removeItem('childName')
    this.props.history.push('/')
  }

  render() {
    return (
      <div>
        <Header />
        <section className={styles.MainConent}>
          <Modal
            show={this.state.showModal}
            modalClosed={this.handleClose}
            modalType={'forgot'}
          >
            <InvalidInvite
              handleClose={this.handleClose}
              showTitle="Invalid Invite Code"
              showResult="The invite code is invalid. This can happen if the code is malformed, expired, or has already been used."
            />
          </Modal>
        </section>
        <Footer />
      </div>
    )
  }
}

export default Invite
