import React, { Component } from 'react'

import styles from './Account.module.css'

import ArrowOpen from './../../../assets/images/arrow_open.png'
import ArrowClose from './../../../assets/images/arrow_close.png'

import firebase, { db } from '../../../firebase/Firebase'

class Account extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountContent: true,
      firstName: '',
      lastName: '',
    }
  }

  handleAccount = () => {
    this.setState({
      accountContent: !this.state.accountContent,
    })
  }

  componentDidMount() {
    let userID = localStorage.getItem('userId')
    db.ref('account/' + userID).once('value', (snapshot) => {
      let checkAccount = snapshot.exists()
      if (checkAccount) {
        let savedFirstName = ''
        let savedLastName = ''
        snapshot.forEach((snap) => {
          savedFirstName = snap.val().firstName
          savedLastName = snap.val().lastName
        })

        this.setState({
          firstName: savedFirstName,
          lastName: savedLastName,
        })
      }
    })
  }

  handleEditAccount = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
        alert(error.message)
      })
    localStorage.clear()
    window.location.reload(false)
  }

  render() {
    const openAccountContents = [styles.AccountContents, styles.Open]
    const closedAccountContents = [styles.AccountContents, styles.Close]
    const closedSubTitle = [styles.SubTitle, styles.SubClosed]

    return (
      <div className={styles.Account}>
        <div
          className={
            this.state.accountContent
              ? styles.SubTitle
              : closedSubTitle.join(' ')
          }
          onClick={this.handleAccount}
        >
          <p>Account</p>
          <img src={this.state.accountContent ? ArrowOpen : ArrowClose} />
        </div>
        <div
          className={
            this.state.accountContent
              ? openAccountContents.join(' ')
              : closedAccountContents.join(' ')
          }
        >
          <div className={styles.AccountContent}>
            <div className={styles.AccountSubContent}>
              <span>First Name</span>
              <p>{this.props.firstName}</p>
            </div>
            <div className={styles.AccountSubContent}>
              <span>Last Name</span>
              <p>{this.props.lastName}</p>
            </div>
          </div>
          <div className={styles.AccountContent}>
            <span>Email</span>
            <p>{this.props.email}</p>
          </div>
          <div className={styles.AccountContent}>
            <span>Password</span>
            <p
              className={styles.AccountChange}
              onClick={this.props.showChangeModal}
            >
              Change
            </p>
          </div>
          <div className={styles.AccoutEdit} onClick={this.handleEditAccount}>
            {/* <span>Edit Account</span> */}
            <span>Logout</span>
          </div>
        </div>
      </div>
    )
  }
}

export default Account
