import React from 'react'

import styles from './Footer.module.css'

const footer = (props) => {
  return (
    <footer>
      <div className={styles.Container}>
        <div className={styles.CopyrightContent}>
          <span className={styles.Copyright}>Copyrights</span>
        </div>
        <div className={styles.Menu}>
          <ul className={styles.MenuItems}>
            <li>
              <a href="#">Kidstory.life</a>
            </li>
            <li>
              <a href="#">Features</a>
            </li>
            <li>
              <a href="#">About</a>
            </li>
            <li>
              <a href="mailto:carolinepaxton@rogers.com">Contact us</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default footer
