import React, { Component } from 'react'

import styles from './Settings.module.css'

import ArrowOpen from './../../../assets/images/arrow_open.png'
import ArrowClose from './../../../assets/images/arrow_close.png'
import { db } from '../../../firebase/Firebase'

class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      settingsContent: true,
    }
  }

  componentDidMount() {
    this.getCheckboxStatus()
  }

  handleSettings = () => {
    this.setState({
      settingsContent: !this.state.settingsContent,
    })
  }

  getCheckboxStatus = async () => {
    let uid = localStorage.getItem('userId')
    let likeCheckbox = document.getElementById('checkbox1')
    let commentCheckbox = document.getElementById('checkbox2')
    let birthCheckbox = document.getElementById('checkbox3')

    await db
      .ref('account/' + uid)
      .once('value')
      .then(function (snapshot) {
        let checkAccount = snapshot.exists()
        if (checkAccount) {
          snapshot.forEach((snap) => {
            if (snap.key === 'userBirthCheck') {
              birthCheckbox.checked = snap.val() == '1' ? true : false
            } else if (snap.key === 'userCommentCheck') {
              commentCheckbox.checked = snap.val() == '1' ? true : false
            } else if (snap.key === 'userLikeCheck') {
              likeCheckbox.checked = snap.val() == '1' ? true : false
            }
          })
        } else {
          likeCheckbox.checked = false
          commentCheckbox.checked = false
          birthCheckbox.checked = false
        }
      })
      .catch((error) => {
        alert(error.message)
        console.log('HandleAccount error', error.message)
      })
  }

  handleLikeCheck = async () => {
    let uid = localStorage.getItem('userId')
    let likeCheckbox = document.getElementById('checkbox1')

    await db
      .ref('account/' + uid)
      .once('value')
      .then(function (snapshot) {
        db.ref('account/' + uid).update({
          userLikeCheck: likeCheckbox.checked == true ? '1' : '0',
        })
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  handleCommentCheck = async () => {
    let uid = localStorage.getItem('userId')
    let commentCheckbox = document.getElementById('checkbox2')

    await db
      .ref('account/' + uid)
      .once('value')
      .then(function (snapshot) {
        db.ref('account/' + uid).update({
          userCommentCheck: commentCheckbox.checked == true ? '1' : '0',
        })
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  handleBirthCheck = async () => {
    let uid = localStorage.getItem('userId')
    let birthCheckbox = document.getElementById('checkbox3')

    await db
      .ref('account/' + uid)
      .once('value')
      .then(function (snapshot) {
        db.ref('account/' + uid).update({
          userBirthCheck: birthCheckbox.checked == true ? '1' : '0',
        })
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  render() {
    const openSettingsContents = [styles.SettingsContents, styles.Open]
    const closedSettingsContents = [styles.SettingsContents, styles.Close]
    const closedSettings = [styles.Settings, styles.ClosedSettings]
    const closedSubTitle = [styles.SubTitle, styles.ClosedSubTitle]

    return (
      // <div className={this.state.settingsContent ? styles.Settings : closedSettings.join(' ')}>
      <div className={styles.Settings}>
        <div
          className={
            this.state.settingsContent
              ? styles.SubTitle
              : closedSubTitle.join(' ')
          }
          onClick={this.handleSettings}
        >
          <p>Settings</p>
          <img src={this.state.settingsContent ? ArrowOpen : ArrowClose} />
        </div>
        <div
          className={
            this.state.settingsContent
              ? openSettingsContents.join(' ')
              : closedSettingsContents.join(' ')
          }
        >
          <div className={styles.SettingsEmail}>
            <p>Notify me when</p>
          </div>
          <label className={styles.SettingsContainer}>
            New like added
            <input
              type="checkbox"
              id="checkbox1"
              onClick={this.handleLikeCheck}
            />
            <span className={styles.Checkmark}></span>
          </label>
          <label className={styles.SettingsContainer}>
            New comment added
            <input
              type="checkbox"
              id="checkbox2"
              onClick={this.handleCommentCheck}
            />
            <span className={styles.Checkmark}></span>
          </label>
          <label className={styles.SettingsContainer}>
            Birthdays
            <input
              type="checkbox"
              id="checkbox3"
              onClick={this.handleBirthCheck}
            />
            <span className={styles.Checkmark}></span>
          </label>
        </div>
      </div>
    )
  }
}

export default Settings
