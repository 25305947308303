import React, { Component } from 'react'

import styles from './PrivacyPolicy.module.css'

import ArrowClose from './../../../assets/images/arrow_close.png'

class PrivacyPolicy extends Component {
  render() {
    let privacyURL = 'https://www.kidstory.life/privacy'
    return (
      <div className={styles.PrivacyPolicy}>
        <a href={privacyURL} target="_blank" className={styles.SubTitle}>
          <p>Privacy policy</p>
          <img src={ArrowClose} />
        </a>
      </div>
    )
  }
}

export default PrivacyPolicy
