import React from 'react'

import styles from './AppStore.module.css'
import appStoreIcon from '../../../../assets/images/appstore_icon.svg'

const appStore = (props) => {
  let appStoreURL =
    'https://apps.apple.com/ca/app/kidstory-memories/id1385761112'
  return (
    <a href={appStoreURL} target="_blank">
      <img src={appStoreIcon} className={styles.AppStoreImg} alt="AppStore" />
    </a>
  )
}

export default appStore
