import React from 'react'

import styles from './NewPassword.module.css'
import CloseIcon from '../../../../assets/images/close.jpg'

const NewPassword = (props) => {
  return (
    <div className={styles.Container}>
      <div className={styles.HeaderContent}>
        <span className={styles.HeaderTitle}>{props.showTitle}</span>
        <img
          className={styles.Close}
          src={CloseIcon}
          onClick={props.handleClose}
        />
      </div>
      <div className={styles.MainContent}>
        <p className={styles.InfoTitle}>{props.showResult}</p>
        {props.sentSuccess ? (
          <button className={styles.Button} onClick={props.handleSuccess}>
            Ok
          </button>
        ) : (
          <button className={styles.Button} onClick={props.handleClose}>
            Close
          </button>
        )}
      </div>
    </div>
  )
}

export default NewPassword
